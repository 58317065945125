<template>
  <export-date-range title="Export Venue Recommendations" export_path="/v1/export/venue_recommendations/"/>
</template>
<script>
import ExportDateRange from "@/views/Widgets/Reports/ExportDateRange";
export default {
  components: {
    ExportDateRange,
  },
};
</script>