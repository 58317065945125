<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Venues</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template slot="header">
            <div class="row align-items-center">
              <div class="col-8">
                <h3 class="mb-0" id="venues_heading">Venues</h3>
              </div>
              <div class="col-4 text-right">
                <a @click.stop="modalExportVenueRecommendations=true"  class="btn btn-primary btn-sm">Export Venue Recommendations</a>
              </div>
            </div>
          </template>
          <div>
            <div class="col-12 text-right">
              <div>
                <base-input
                  id="search_input"
                  v-model="searchQuery"
                  prepend-icon="fas fa-search"
                  placeholder="Search..."
                />
              </div>
            </div>

            <el-table
              :data="tableData"
              row-key="id"
              id="venues_data_table"
              v-loading="loading"
              header-row-class-name="thead-light"
              @sort-change="sortChange"
              @row-click="rowClicked"
            >
              <el-table-column
                label="Name"
                min-width="220px"
                prop="name"
                sortable
              >
                <template v-slot="{ row }">
                  <div class="media align-items-center">
                    <div class="media-body">
                      <span class="font-weight-600 name mb-0 text-sm">{{
                        row.name
                      }}</span>
                    </div>
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="Address" prop="address" min-width="250px">
                <template v-slot="{ row }">
                  {{ row.address.address }}
                </template>
              </el-table-column>

              <el-table-column label="Venue Specialist" min-width="250px">
                <template v-slot="{ row }">
                  {{ row.venue_specialist_name }}
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div>
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
    <modal
      :show.sync="modalExportVenueRecommendations"
      body-classes="p-0"
      modal-classes="modal-dialog-centered modal-md"
    >
      <ExportVenueRecommendations/>
    </modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { Table, TableColumn, Select, Option, Loading } from "element-ui";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { BasePagination } from "@/components";
import tableMixin from "@/helpers/tableMixin";
import ExportVenueRecommendations from '@/views/Widgets/Venues/ExportVenueRecommendations';

export default {
  mixins: [tableMixin],
  components: {
    BasePagination,
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    ExportVenueRecommendations,
  },
  data() {
    return {
      propsToSearch: "name",
      loading: true,
      modalExportVenueRecommendations: false,
    };
  },
  async created() {
    await this.$store.dispatch("venues/getAll", {country: this.country});
    this.loading = false;
  },
  computed: {
    queriedData() {
      let data =  [...this.$store.state.venues.items];
      if (this.searchQuery) {
        const propsToSearch = this.propsToSearch;
        const searchQuery = this.searchQuery;
        data = data.filter(function(item) {
          return item[propsToSearch].toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1;
        });
      }
      
      return data;
    },
    tableData() {
      let data = [...this.queriedData];
      data = data.slice(this.from, this.from + this.pagination.perPage);
      return data;
    },
    ...mapState("country/", {
      country: (state) => state.country || 'AU',
    }),
  },
  methods: {
    rowClicked(row) {
      this.$router.push({ name: 'VenueDetails', params: {id: row.id} });
    },
  },
};
</script>

<style>
.no-border-card .card-footer {
  border-top: 0;
}
.el-table .cell {
  word-break: normal;
}
.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #f6f9fc !important;
  cursor: pointer;
}
</style>
